/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Clients
-----------------------------------------------------------------*/


.clients-grid,
.testimonials-grid {
	list-style: none;
	overflow: hidden;
	li {
		float: left;
		position: relative;
		width: 20%;
		padding: 20px 0;
		backface-visibility: hidden;
	}
}

.clients-grid {
	&.grid-2 {
		li {
			width: 50%;
			padding: 25px 0;
		}
	}
	&.grid-3 {
		li {
			width: 33.33%;
		}
	}
	&.grid-4 {
		li {
			width: 25%;
		}
	}
	&.grid-6 {
		li {
			width: 16.66%;
		}
	}
	li {
		a {
			opacity: 0.6;
			&:hover {
				opacity: 1;
			}
		}
	}
}
body {
	&:not(.device-touch) {
		.clients-grid {
			li {
				a {
					-webkit-transition: all .5s ease;
					-o-transition: all .5s ease;
					transition: all .5s ease;
				}
			}
		}
	}
}

.clients-grid li a,
.clients-grid li img {
	display: block;
	width: 75%;
	margin-left: auto;
	margin-right: auto;
}

.clients-grid {
	li {
		a {
			img {
				width: 100%;
			}
		}
	}
}

.clients-grid li:before,
.clients-grid li:after,
.testimonials-grid li:before,
.testimonials-grid li:after {
	content: '';
	position: absolute;
}

.clients-grid li:before,
.testimonials-grid li:before {
	height: 100%;
	top: 0;
	left: -1px;
	border-left: 1px dashed #DDD;
}

.clients-grid li:after,
.testimonials-grid li:after {
	width: 100%;
	height: 0;
	top: auto;
	left: 0;
	bottom: -1px;
	border-bottom: 1px dashed #DDD;
}

