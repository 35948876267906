/*-----------------------------------------------------------------------------------
	less/responsive.scss
-----------------------------------------------------------------------------------*/


@import "variables";
@import "mixins";

@media only screen and (max-width: 1382px) {
	#logo img {
		height: 80px !important;
	}
}

@media only screen and (max-width: 1199.98px) {
	#primary-menu ul.primary-menu-list li>a {
		margin-right: 20px;
	}

	canvas {
		max-width: 100%;
		height: auto !important;
	}

	.promo {
		h3 {
			font-size: 20px;
		}
	}

	.promo > span,
	.promo > .container > span {
		font-size: 15px;
	}

	.promo-uppercase {
		h3 {
			font-size: 19px;
		}
	}

	.promo-uppercase > span,
	.promo-uppercase > .container > span {
		font-size: 14px;
	}

}

@media (max-width: 1183px) {
	#primary-menu ul.primary-menu-list li>a {
		font-size: 12px;
		margin-right: 15px;
	}

	#primary-menu ul.primary-menu-list>li.sub-menu {
		a {
			padding-right: 17px;

			&:after {
				transform: rotate(90deg) scale(.7);
			}
		}
	}

	#primary-menu {
		.button.button-large {
			padding: 0 15px;
		}
	}

	#logo {
		margin-right: 20px;
	}

	#logo img {
		height: 70px !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {

	#wrapper {
		width: 1000px;
	}

	#header {
		&.transparent-header {
			&.floating-header {
				.container {
					width: 1030px;
					padding: 0 30px;
				}
				&.sticky-header {
					.container {
						width: 970px;
						padding: 0 15px;
					}
				}
			}
		}
	}

	#primary-menu {
		&.sub-title {
			ul {
				> li {
					> a {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
		}
	}

	#primary-menu ul li.mega-menu .mega-menu-content,
		.floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content {
		width: 940px;
	}

	.floating-header {
		#primary-menu {
			ul {
				li {
					&.mega-menu {
						.mega-menu-content {
							width: 1030px;
						}
					}
				}
			}
		}
	}

	.team {
		&.team-list {
			.team-image {
				width: 200px;
			}
		}
	}

	.testi-image,
	.testi-image a,
	.testi-image img,
	.testi-image i {
		display: block;
		width: 36px;
		height: 36px;
	}

	.testi-image {
		i {
			line-height: 36px;
			font-size: 20px;
		}
	}

	/* ----------------------------------------------------------------
	Portfolio
	-----------------------------------------------------------------*/

	/* Portfolio - Sidebar
	-----------------------------------------------------------------*/

	/* Portfolio - Sidebar
	-----------------------------------------------------------------*/

	.bothsidebar .portfolio-item { width: 50%; }

	/* Portfolio - Both Sidebars - Items - 2 Columns
	-----------------------------------------------------------------*/

	.bothsidebar .portfolio.portfolio-3 { margin: 0 -20px -20px 0; }

	.bothsidebar .portfolio-3 .portfolio-item { padding: 0 20px 20px 0; }

	/* Portfolio - Items - 1 Column
	-----------------------------------------------------------------*/

	.bothsidebar .portfolio-shuffle,
	.postcontent .portfolio-1 .iconlist { display: none; }

	/* Blog - Small
	-----------------------------------------------------------------*/

	.postcontent .small-thumbs .entry-image { width: 200px; }

	.bothsidebar .small-thumbs .entry-image { width: 120px; }


	.post-timeline .entry { width: 400px !important; }

	.post-timeline .entry.entry-date-section { width: 100% !important; }


	/* Blog - Medium Post List
	-----------------------------------------------------------------*/

	.mpost .entry-image,
	.mpost .entry-image a,
	.mpost .entry-image img,
	.mpost .entry-image i {
		width: 120px;
		height: 90px;
	}

	.mpost .entry-image a i { line-height: 90px; }

	.col_one_third .landing-wide-form .heading-block h2 { font-size: 32px; }

	.col_one_third .landing-wide-form .heading-block span { font-size: 17px; }

	.col_one_third .landing-wide-form { padding: 36px 30px; }


	/* Shop - 1 Column - Both Sidebar
	-----------------------------------------------------------------*/

	.bothsidebar .product-1 .product-image {
		width: 200px;
		height: 267px;
	}

	.product-title h3 { font-size: 18px; }

	.landing-video {
		width: 465px;
		height: 262px;
		margin: 19px 0 0 75px;
	}

	.panel .portfolio-meta li { padding-left: 22px; }

	.panel .portfolio-meta li span {
		display: block;
		margin-left: -21px;
	}

	.portfolio-single-image-full.portfolio-single-thumbs,
	.portfolio-single-image-full .swiper-container { height: 400px; }

	.bnews-slider { width: 817px; }

	.landing-form-overlay { bottom: -142px; }

}


@media only screen and (max-width: 991px) {
	#primary-menu ul.primary-menu-list li>a {
		color: var(--dark-color);
	}

	.home-slider {
		.slick-next, .slick-prev {
			top: 25% !important;
		}
	}

	#banner .hero-slide {
		display: flex;
    	flex-direction: column;
	}

	.button.custom {
		// border-color: var(--theme-color) !important;
		// color: var(--dark-color) !important;

		&:hover {
			border-color: var(--theme-color) !important;
			color: white !important;
		}

		&:before {
			background-color: var(--theme-color) !important;
		}
	}

	.banner-caption {
		position: relative;
		background-color: #144824;
		height: auto;
		padding: 40px 0;
	}

	#content .content-wrap {
		padding: 60px 0;
	}

	#logo a {
		height: 100%;
	}

	#logo img {
		height: 55px !important;
	}

	#header-wrap {
		.container {
			padding-top: 42px;
		}
	}

	.header-socials {
		border-right: none;
    	margin-right: 0;
		padding-right: 0;
		display: flex;
		align-items: center;

		span {
			font-size: 12px;
		}
	}

	.primary-menu-wrap {
		position: absolute;
		top: 0;
		left: 0;
		justify-content: center !important;
		margin-bottom: 0 !important;
		padding: 10px 20px;
		border-bottom: 1px solid #555;
	}

	#primary-menu .button-custom {
		background: none;
		color: var(--dark-color) !important;
		border-radius: 0;
		text-shadow: none;

		&:hover, &:focus {
			background: none;
			color: white !important;
		}
	}

	#top-account .top-account-content {
		right: 0px;
		width: 200px;
	}

	.row-article {
		flex-direction: column-reverse;
	}

	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}

	#header.full-header .container,
	.container-fullwidth {
		padding: 0 15px !important;
		margin: 0 auto;
		width: 750px !important;
	}

	.container {
		&.vertical-middle {
			width: 100% !important;
		}
	}

	.vertical-middle {
		& + .video-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.sub-banner-caption h2 {
		font-size: 20px;
		line-height: 1.1;
	}

	.postcontent,
	.postcontent.bothsidebar,
	.sidebar {
		width: 100%;
		margin-right: 0;
		float: none !important;
		margin-bottom: 40px !important;
		padding-bottom: 40px;
		border-bottom: 1px solid #EEE;
	}

	.sidebar {
		&.col_last {
			float: none;
			margin-bottom: 0 !important;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	/* ----------------------------------------------------------------
		Header
	-----------------------------------------------------------------*/

	#header,
	#header-wrap,
	#header.sticky-style-2,
	#header.sticky-style-3,
	#header.sticky-style-2 #header-wrap,
	#header.sticky-style-3 #header-wrap {
		height: auto !important;
	}

	#header {
		min-height: 142px;
		overflow: hidden;
	}

	#header-wrap {
		z-index: 199;
		// position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #25080c;
		box-shadow: 0 0 10px rgb(0 0 0 / 10%);
	}

	#header.sticky-style-2 #header-wrap,
	#header.sticky-style-3 #header-wrap {
		min-height: 60px;
	}

	#header {
		&.sticky-header {
			#header-wrap {
				position: relative;
			}
		}
		&.transparent-header {
			&.floating-header {
				margin-top: 0;
				.container {
					width: 750px;
					padding: 0 15px;
					border-radius: 0;
				}
			}
			& + #page-title {
				&.page-title-parallax {
					.container {
						padding-top: 0;
					}
				}
			}
		}
	}

	#header.transparent-header,
	#header.semi-transparent {
		background: #FFF;
		border-bottom: 1px solid #F5F5F5;
		z-index: auto;
	}

	#header.dark.transparent-header,
	#header.dark.semi-transparent {
		background-color: #333;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}

	#header.transparent-header + #slider,
	#header.transparent-header + #page-title.page-title-parallax,
	#slider + #header.transparent-header,
	#header.transparent-header.floating-header + #slider,
	#header.transparent-header + #google-map {
		top: 0;
		margin-bottom: 0;
	}

	.slider-parallax .slider-parallax-inner { position: relative; }

	#header.transparent-header + #page-title.page-title-parallax .container { padding-top: 0; }

	body:not(.primary-menu-open) #header.dark.transparent-header-responsive,
	body:not(.primary-menu-open) #header.transparent-header-responsive {
	    background-color: transparent;
	    border: none;
	}

	body:not(.primary-menu-open) #header.semi-transparent.transparent-header-responsive {
		background-color: rgba(255,255,255,0.8);
		z-index: 1;
	}

	body:not(.primary-menu-open) #header.transparent-header-responsive + #slider,
	body:not(.primary-menu-open) #header.transparent-header-responsive + #page-title.page-title-parallax,
	body:not(.primary-menu-open) #slider + #header.transparent-header-responsive,
	body:not(.primary-menu-open) #header.transparent-header-responsive.floating-header + #slider,
	body:not(.primary-menu-open) #header.transparent-header-responsive + #google-map {
		top: -101px;
		margin-bottom: -101px;
	}

	#header-trigger,
	.top-advert,
	.header-extras {
		display: none;
	}

	#logo {
		display: block;
		height: 100px;
		float: none;
		margin: 0 auto 0 !important;
		max-width: none;
		text-align: center;
		border: 0 !important;
		padding: 0 !important;
		a {
			&.standard-logo {
				display: flex;
				justify-content: center;
			}
		}
	}

	/* ----------------------------------------------------------------
		Primary Menu
	-----------------------------------------------------------------*/

	#primary-menu {
		display: block;
		float: none;
		height: auto!important;
	}

	#primary-menu-trigger {
		opacity: 1;
		pointer-events: auto;
		top: 67px;
		margin-top: 0;
		left: 0;
		z-index: 1;
	}

	#primary-menu .container #primary-menu-trigger {
		top: 5px;
		left: 0;
	}

	#primary-menu > ul.primary-menu-list,
	#primary-menu > div > ul {
		display: none;
		float: none !important;
		border: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
		background-color: white;
	}

	#primary-menu > div > ul { padding-top: 60px !important; }

	body:not(.top-search-open) #primary-menu-trigger {
		opacity: 1;
		-webkit-transition: opacity .2s .2s ease, top .4s ease;
		-o-transition: opacity .2s .2s ease, top .4s ease;
		transition: opacity .2s .2s ease, top .4s ease;
	}

	// body.top-search-open #primary-menu-trigger { opacity: 0; }

	body.side-push-panel #side-panel-trigger-close a {
		display: block;
		position: absolute;
		z-index: 12;
		top: 0;
		left: auto;
		right: 0;
		width: 40px;
		height: 40px;
		font-size: 18px;
		line-height: 40px;
		color: #444;
		text-align: center;
		background-color: rgba(0,0,0,0.1);
		border-radius: 0 0 0 2px;
	}

	#primary-menu.style-5 > ul {
		padding-right: 0;
		margin-right: 0;
		border-right: 0;
	}

	#primary-menu ul.primary-menu-list li {
		float: none;
		margin: 0 !important;
		text-align: left !important;
		border-top: 1px solid #EEE;
	}

	#primary-menu ul.primary-menu-list>li {
		display: block;
	}

	#header.split-menu #primary-menu:not(.mobile-menu-off-canvas) > ul:not(:first-child) > li:first-child { border-top: 1px solid #EEE; }

	#primary-menu ul.primary-menu-list li:first-child { border-top: 0; }

	#primary-menu ul.primary-menu-list > li > a,
	#primary-menu.style-2 > div > ul > li > a {
		padding: 14px 5px !important;
		border: none !important;
		margin: 0 !important;
		transition: all 0.3s ease;

		&:before, &:after {
			content: "";
			position: absolute;
			top: -1px !important;
			left: 0 !important;
			bottom: -1px !important;
			right: 0 !important;
			transition: all 0.3s ease !important;
			background-color: white;
		}

		&:before {
			transform: translateX(-99%);
		}

		&:after {
			transform: translateX(99%) !important;
			background-image: none;
			height: initial !important;
			width: 100% !important;
		}

		&:hover, &:hover:before, &:hover:after {
			background-color: var(--theme-color);
			color: white;
		}
	}

	#primary-menu ul.primary-menu-list li.current > a,
	#primary-menu.style-2 > div > ul li.current > a {
		background-color: var(--theme-color) !important;
		color: white;
		&:before, &:after {
			background-color: var(--theme-color) !important;
		}
	}

	#primary-menu ul.primary-menu-list li.sub-menu ul > li.current > a {
		&:before {
			background-color: transparent !important;
		}
	}

	#primary-menu.style-5 > ul > li > a > div { line-height: 22px; }

	#primary-menu.style-5 > ul > li > a > div i {
		display: inline-block;
		margin: 0 6px 0 0;
		top: -1px;
		width: 16px;
		font-size: 14px;
		line-height: inherit;
	}

	#primary-menu.style-6 > ul > li > a:after,
	#primary-menu.style-6 > ul > li.current > a:after { display: none; }

	#primary-menu.sub-title > ul > li,
	#primary-menu.sub-title.style-2 > div > ul > li {
		background: none !important;
		margin-left: 0;
	}

	#primary-menu.sub-title > ul > li > a,
	#primary-menu.sub-title > div > ul > li > a {
		line-height: 22px;
		font-size: 13px;
		border-top: none;
	}

	#primary-menu.sub-title ul li > a span { display: none !important; }

	#primary-menu.sub-title ul li:hover > a,
	#primary-menu.sub-title ul li.current > a,
	#primary-menu.sub-title div ul > li:hover > a,
	#primary-menu.sub-title div ul > li.current > a,
	#primary-menu.style-3 ul li:hover > a,
	#primary-menu.style-3 ul li.current > a {
		background-color: transparent !important;
		color: #444;
		text-shadow: none;
	}

	#primary-menu ul:not(.windows-mobile-menu) li > a i.icon-angle-down:last-child { display: none; }

	#primary-menu > ul > li.sub-menu > a,
	#primary-menu > .container > ul > li.sub-menu > a {
		background-image: url("../images/icons/submenu.png");
		background-position: right center;
		background-repeat: no-repeat;

		&:hover {
			background-image: url("../images/icons/submenu-dark.png");
			background-blend-mode: screen;
		}
	}

	#primary-menu > ul > li.current.sub-menu > a {
		background-image: url("../images/icons/submenu-dark.png");
		background-blend-mode: screen;
	}

	#primary-menu ul.primary-menu-list li.mega-menu { position: relative; }


	#primary-menu ul.primary-menu-list ul:not(.mega-menu-column),
	#primary-menu ul.primary-menu-list li .mega-menu-content {
		position: relative;
		width: auto;
		max-width: none;
		background: transparent !important;
		box-shadow: none;
		border: 0;
		border-top: 1px solid #EEE;
		z-index: 1;
		top: 0;
		padding-left: 15px;
	}

	#primary-menu.on-click ul.primary-menu-list li .mega-menu-content.d-block { display: block !important; }

	#primary-menu ul.primary-menu-list li .mega-menu-content { padding-left: 0; }

	#primary-menu ul.primary-menu-list ul:not(.mega-menu-column) ul {
		top: 0 !important;
		left: 0 !important;
	}

	#primary-menu ul.primary-menu-list ul.menu-pos-invert:not(.mega-menu-column),
	#primary-menu ul.primary-menu-list li .mega-menu-content.menu-pos-invert {
		left: 0;
		right: 0;
	}

	#primary-menu ul.primary-menu-list ul:not(.mega-menu-column) ul.menu-pos-invert { right: 0; }

	#primary-menu ul.primary-menu-list ul li {
		float: none;
		margin: 0;
		border-top: 1px solid #EEE;
	}

	#primary-menu ul.primary-menu-list ul li > a {
		padding: 11px 5px !important;
		font-size: 13px;
		text-transform: none;
		font-weight: 400;

		&:before, &:after {
			content: "" !important;
			position: absolute;
			top: 0 !important;
			left: 0;
			bottom: -1px;
			right: 0;
			transition: all 0.3s ease;
			background-color: white;
			background-image: none !important;
			height: initial!important;
			width: initial!important;
		}

		&:before {
			transform: translateX(-100%);
		}

		&:after {
			transform: translateX(100%) !important;
		}
	}

	#primary-menu ul.primary-menu-list ul li:hover > a {
		background-color: transparent !important;
		font-weight: 700;
		padding-left: 5px;
		color: var(--theme-color);
	}

	#primary-menu ul.primary-menu-list li .mega-menu-content,
	#primary-menu ul.primary-menu-list li.mega-menu-small .mega-menu-content,
	#primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content,
	.floating-header.sticky-header #primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content,
	.floating-header #primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content {
		width: auto;
		margin: 0;
		top: 0;
	}

	.container-fullwidth #primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content,
	#header.full-header #primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content {
		width: auto !important;
		margin: 0 !important;
	}

	#primary-menu ul.primary-menu-list li.mega-menu .mega-menu-content.style-2 { padding: 0; }

	#primary-menu ul.primary-menu-list li .mega-menu-content ul {
		display: block !important;
		position: relative;
		opacity: 1 !important;
		top: 0;
		left: 0;
		min-width: inherit;
	}

	#primary-menu ul.primary-menu-list li .mega-menu-content ul ul { border-top: 1px solid #EEE; }

	#primary-menu ul.primary-menu-list li .mega-menu-content ul.mega-menu-column {
		float: none;
		width: auto;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		margin: 0;
		border-left: 0 !important;
		padding-left: 15px;
		border-top: 1px solid #EEE;
	}

	#primary-menu ul.primary-menu-list li .mega-menu-content ul.mega-menu-column:first-child { border: 0; }

	#primary-menu ul.primary-menu-list li .mega-menu-content ul { width: 100% !important; }

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 ul.mega-menu-column { padding: 0 0 0 15px; }

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title { margin: 0; }

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a {
		font-size: 13px;
		font-weight: 700;
		text-transform: none !important;
		padding: 11px 5px !important;
		line-height: 22px !important;
		color: #444 !important;
	}

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title:hover > a {
		background-color: transparent;
		color: var(--theme-color) !important;
	}

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 ul.mega-menu-column > li.mega-menu-title > a:hover { color: var(--theme-color) !important; }

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 li { border-top: 1px solid #EEE; }

	#primary-menu ul.primary-menu-list li .mega-menu-content.style-2 li:first-child { border-top: 0; }

	#primary-menu ul li .mega-menu-content.style-2 ul > li > a:hover { padding-left: 5px; }

	.mega-menu-column .widget { padding: 30px 0; }

	/* Top Search
	-----------------------------------------------------------------*/

	#top-search {
		margin: 0 !important;
		form {
			height: 100px !important;
			input {
				font-size: 24px;
			}
		}
	}

	#top-search a,
	#top-cart,
	#side-panel-trigger,
	#top-account,
	#top-account-inactive {
		position: absolute;
		top: 42px;
		left: auto;
		right: 15px;
		margin: 40px 0;
		-webkit-transition: margin .4s ease;
		-o-transition: margin .4s ease;
		transition: margin .4s ease;
	}

	#top-search a { right: 25px; }

	#side-panel-trigger { right: 71px; }

	#top-account, #top-account-inactive {
		// margin: 33px 0 32px;
		right: 43px;
	}

	#top-account a.btn span { display: none; }

	#primary-menu {
		> div {
			> #top-search a,
			> #top-cart,
			> #side-panel-trigger {
				margin: 20px 0 !important;
			}
		}
		ul.windows-mobile-menu {
			li.sub-menu { position: relative; }

			li.sub-menu a { background-image: none !important; }

			li.sub-menu a.wn-submenu-trigger {
				position: absolute;
				cursor: pointer;
				width: 32px;
				top: 0;
				right: 0;
				z-index: 1;
				padding: 0 !important;
				text-align: center !important;
			}

			.mega-menu-content a.wn-submenu-trigger { display: none; }

			li.sub-menu a.wn-submenu-trigger i { margin: 0 !important; }
		}
	}

	#header {
		&.sticky-style-3 {
			#top-cart {
				.top-cart-content {
					top: 40px;
				}
			}
		}
	}

	/* Page Menu
	-----------------------------------------------------------------*/

	body:not(.sticky-responsive-pagemenu) #page-menu #page-menu-wrap {
		position: relative !important;
		top: 0 !important;
	}

	#page-menu {
		&.pagemenu-active {
			#page-submenu-trigger {
				background-color: rgba(0,0,0,0.2);
			}
			nav {
				display: block;
			}
		}
		nav {
			display: none;
			position: absolute;
			float: none;
			width: 200px;
			top: 43px;
			left: auto;
			right: 15px;
			height: auto;
			background-color: var(--theme-color);
			z-index: 11;
			ul {
				height: auto;
				background-color: rgba(0,0,0,0.2);
			}
			li {
				float: none;
				a {
					height: 40px;
					line-height: 40px;
					margin: 0;
					padding: 0 15px;
					border-radius: 0;
				}
			}
		}
	}

	#page-submenu-trigger {
		opacity: 1;
		pointer-events: auto;
		color: #FFF;
	}

	/* Responsive Sticky Header
	-----------------------------------------------------------------*/

	.sticky-responsive-menu {
		#header {
			min-height: 100px;
			-webkit-transition: min-height .4s ease;
			-o-transition: min-height .4s ease;
			transition: min-height .4s ease;
		}

		#header-wrap {
			-webkit-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
		}

		&.device-touch {
			#header,
			#header-wrap,
			#logo,
			#logo img,
			#top-search a,
			#primary-menu-trigger,
			#top-cart,
			#side-panel-trigger,
			#top-account {
				-webkit-transition: none !important;
				-o-transition: none !important;
				transition: none !important;
			}
		}
	}

	.sticky-responsive-menu #header.sticky-style-2,
	.sticky-responsive-menu #header.sticky-style-3 { min-height: 160px; }

	#header.responsive-sticky-header:not(.sticky-style-2):not(.sticky-style-3):not(.static-sticky) { min-height: 60px; }

	.responsive-sticky-header{
		#header-wrap {
			z-index: 199;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: #FFF;
			border-bottom: 1px solid #EEE;
			backface-visibility: hidden;
		}

		&:not(.static-sticky) #logo,
		&:not(.static-sticky) #logo img { height: 60px; }

		&:not(.static-sticky) #primary-menu-trigger { top: 5px; }

		#primary-menu > ul,
		#primary-menu > .container > ul {
			max-height: 300px;
			overflow-y: scroll;
			margin-bottom: 20px;
		}

		#primary-menu > ul,
		#primary-menu > .container > ul { margin-bottom: 20px !important; }

		&:not(.static-sticky) {
			#top-search a,
			#top-cart,
			#side-panel-trigger { margin: 20px 0; }
			#top-search form { height: 60px !important; }
			#top-cart .top-cart-content { top: 40px; }
		}
	}

	#header.responsive-sticky-header:not(.static-sticky) ~ #page-menu.sticky-page-menu #page-menu-wrap { top: 60px; }

	/* Off Canvas Menu
	-----------------------------------------------------------------*/

	body:not(.sticky-responsive-menu) {
		#primary-menu {
			&.mobile-menu-off-canvas {
				> ul,
				> div > ul {
					position: fixed;
					display: block;
					z-index: 499;
					width: 280px;
					height: 100%;
					height: calc(100vh);
					max-height: none !important;
					margin: 0 !important;
					left: 0 !important;
					top: 0 !important;
					padding: 35px 25px 60px !important;
					background-color: #FFF;
					border-right: 1px solid #EEE !important;
					overflow-y: scroll;
					-webkit-overflow-scrolling: touch;
					-webkit-transform: translate3d(-280px,0,0);
					-o-transform: translate3d(-280px,0,0);
					transform: translate3d(-280px,0,0);
					backface-visibility: hidden;
					transform-style: preserve-3d;
				}
				&.from-right > ul,
				&.from-right > div > ul {
					left: auto !important;
					right: 0;
					border-right: 0 !important;
					border-left: 1px solid #EEE !important;
					-webkit-transform: translate3d(280px,0,0);
					-o-transform: translate3d(280px,0,0);
					transform: translate3d(280px,0,0);
				}
				> ul,
				> div > ul {
					-webkit-transition: -webkit-transform .3s ease-in-out, opacity .2s ease !important;
					-o-transition: -o-transform .3s ease-in-out, opacity .2s ease !important;
					transition: transform .3s ease-in-out, opacity .2s ease !important;
					&.d-block {
						-webkit-transform: translate3d(0,0,0);
						-o-transform: translate3d(0,0,0);
						transform: translate3d(0,0,0);
					}
				}
			}
		}
	}

	/* Portfolio
	-----------------------------------------------------------------*/

	.portfolio .portfolio-item { width: 33.33333333%; }

	/* Portfolio - Items - 2 Columns - No Margin
	-----------------------------------------------------------------*/

	.portfolio-2 .portfolio-item { width: 50%; }

	/* Portfolio - Items - 1 Column
	-----------------------------------------------------------------*/

	.portfolio-1 .portfolio-item { width: 100%; }

	.portfolio-1 .portfolio-item .portfolio-image {
		float: none !important;
		margin: 0 !important;
		margin-bottom: 20px !important;
	}

	.portfolio-1 .portfolio-item .portfolio-image,
	.portfolio-1 .portfolio-item .portfolio-image a,
	.portfolio-1 .portfolio-item .portfolio-image img,
	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image,
	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image a,
	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	.portfolio-1 .portfolio-desc {
		width: 100% !important;
		padding: 0 !important;
	}

	/* Portfolio - Items - 1 Column - Full Width
	-----------------------------------------------------------------*/

	.portfolio-1.portfolio-fullwidth { margin: 0 0 -60px 0; }

	.portfolio-1.portfolio-fullwidth .portfolio-item {
		margin-bottom: 60px;
		padding-bottom: 0;
		border-bottom: 0;
		overflow: hidden;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image {
		float: none;
		margin: 0 0 20px 0 !important;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img,
	.portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
		-webkit-transform: translateX(0) !important;
		-moz-transform: translateX(0) !important;
		-ms-transform: translateX(0) !important;
		-o-transform: translateX(0) !important;
		transform: translateX(0) !important;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-desc,
	.portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
		position: relative;
		height: auto;
		background-color: transparent;
		left: 0 !important;
		right: 0 !important;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-overlay a { left: 50% !important; }

	.portfolio-1.portfolio-fullwidth .alt .portfolio-overlay a {
		left: auto;
		right: 50% !important;
	}

	.device-touch .portfolio-parallax .portfolio-item .portfolio-desc,
	.device-touch .portfolio-parallax .portfolio-overlay { opacity: 1; }

	.shop-quick-view-ajax,
	.portfolio-ajax-modal { width: 90% !important; }


	.small-thumbs .entry-title h2,
	.ievent .entry-title h2 { font-size: 18px; }

	.entry-meta li {
		font-size: 12px;
		margin: 0 10px 10px 0;
	}

	/* Blog - Grid
	-----------------------------------------------------------------*/

	.post-grid {
		margin-right: -20px;
		.entry {
			width: 33.33333333%;
			padding-right: 20px;
		}
	}

	.post-grid.grid-2,
	.post-grid.grid-3 {
		margin-right: -30px;
	}

	.post-grid.grid-2 .entry,
	.post-grid.grid-3 .entry {
		width: 50%;
		padding-right: 30px;
	}

	.post-masonry-full {
		.entry {
			width: 33.30% !important;
		}
		&.grid-3 {
			.entry {
				width: 50% !important;
			}
		}
	}

	.post-timeline {
		.entry {
			width: 290px !important;
			&.entry-date-section {
				width: 100% !important;
			}
		}
	}

	/* Shop
	-----------------------------------------------------------------*/

	.product {
		width: 33.33333333%;
		&:not(.product-2):not(.product-3) {
			.product-title {
				h3 {
					font-size: 18px;
				}
			}
		}
	}

	.pricing {
		[class^=col-md-] {
			.pricing-box {
				&.best-price {
					margin-top: 0;
				}
			}
		}
		&.pricing-5 {
			.pricing-box {
				&.best-price {
					margin-top: 20px;
				}
			}
		}
	}

	.device-md .pricing.pricing-5 .pricing-title h3,
	.device-md .pricing.pricing-5 .best-price .pricing-title h3 {
		font-size: 14px;
	}

	.device-md {
		.pricing {
			&.pricing-5 {
				.pricing-title {
					span {
						font-size: 12px;
					}
				}
				.pricing-price {
					font-size: 48px;
					span {
						font-size: 20px;
						&.price-tenure {
							font-size: 12px;
						}
					}
				}
				.pricing-box {
					&.best-price {
						.pricing-title {
							padding: 15px 0;
						}
					}
				}
			}
		}
	}

	.pricing-box.pricing-extended .pricing-desc,
	.pricing-box.pricing-extended .pricing-action-area {
		position: relative;
		float: none;
		width: auto;
		padding: 25px;
	}

	.pricing-box {
		&.pricing-extended {
			.pricing-action-area {
				height: auto;
				border-left: 0;
				border-top: 1px solid rgba(0,0,0,0.05);
				border-radius: 0 0 3px 3px;
			}
		}
	}

	.testimonials-grid {
		&.grid-3 {
			li {
				width: 50%;
			}
		}
	}

	.section {
		& > .testimonial {
			padding: 0 40px !important;
			max-width: none;
		}
	}

	.promo {
		&:not(.promo-full) {
			padding: 30px 20px !important;
		}
		&.promo-dark {
			h3 {
				line-height: 1.6;
			}
		}
		a {
			&.button {
				position: relative !important;
				top: 0 !important;
				margin: 20px 0 0 !important;
				left: 0 !important;
				right: 0;
			}
		}
	}

	#contact-form-overlay-mini {
		float: none;
		width: auto;
		max-width: 380px;
		margin: 0 auto;
	}

	.button {
		&.button-full {
			font-size: 22px;
			i {
				top: 3px !important;
			}
		}
	}

	.bnews-title {
		float: none;
		display: inline-block;
	}

	.bnews-slider {
		float: none;
		margin: 10px 0 0;
		width: 100%;
	}

	.wedding-head {
		font-size: 60px;
		.and {
			font-size: 144px;
		}
	}

	.wedding-head .first-name span,
		.wedding-head .last-name span {
		font-size: 40px;
	}

	.error404 {
		font-size: 180px;
	}

	.landing-form-overlay {
		position: absolute;
		width: 100%;
		max-width: 400px;
		right: auto;
		left: 50%;
		margin-left: -200px;
		bottom: 0;
	}

	.menu-category-list {
		top: 100px;
	}
}

@media (max-width: 974px) {
	.tablet-view {
		background: linear-gradient(to right, #E1A1A5 0%, #C3F5D7 100%);

		.rounded-20px {
			border-radius: 0px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {

	.swiper_wrapper:not(.full-screen):not(.canvas-slider-grid),
	 .swiper_wrapper:not(.full-screen):not(.canvas-slider-grid) .swiper-slide {
		height: 400px !important;
	}

	.sidebar-widgets-wrap {
		position: relative;
		margin: 0 -20px -40px -20px;
		.widget {
			display: inline-block;
			vertical-align: top;
			float: none;
			width: 49.6%;
			margin: 0 0 40px 0;
			padding-top: 0;
			padding-left: 20px;
			padding-right: 20px;
			border-top: 0;
		}
	}

	.landing-video {
		width: 352px;
		height: 199px;
		margin: 14px 0 0 60px;
	}

}

@media only screen and (max-width: 767.98px) {

	.banner-caption hr {
		display: none;
	}

	.select-option {
		.list-inline-item {
			display: block !important;
		}
	}

	// .banner-caption {
	// 	padding-left: 45px;
	// 	padding-right: 45px;
	// }

	.banner-caption h2 {
		font-size: 36px;
		margin-bottom: 20px;
	}

	.banner-caption p {
		font-size: 24px !important;
		margin-bottom: 10px;
	}

	.news-post {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .news-post-img {
    margin-right: 0;
    height: 300px;
  }

  .news-post-img img {
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
  }

  .news-post-info {
    padding: 1em 0;
    margin-left: 0;
  }

  .news-post-info-excerpt {
    max-height: 100%;
  }

  .article-meta-share {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .article-meta-share .article-meta {
    margin-bottom: .5em;
    width: 100%;
  }

  .article-meta-share .article-share {
    width: 100%;
  }

	body {
		padding: 0 !important;
	}

	#wrapper {
		width: 100% !important;
	}

	.container-fullwidth {
		margin: 0 auto;
		padding: 0 15px !important;
	}

	h1 { font-size: 28px; }

	h2 { font-size: 24px; }

	h3 { font-size: 20px; }

	h4 { font-size: 16px; }

	.one-page-arrow {
		display: none !important;
	}

	#top-bar {
		height: auto;
		.col_half {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}

	.top-links,
		.top-links > ul > li {
		float: none !important;
	}

	.top-links {
		& > ul {
			text-align: center;
			& > li {
				display: inline-block;
				margin-right: -4px;
			}
		}
		ul {
			div {
				&.top-link-section {
					left: auto;
					right: 0;
					padding: 20px 15px;
				}
			}
		}
	}

	.top-links ul ul,
	.top-links ul div.top-link-section {
		text-align: left;
	}

	// #gotoTop,
	// .one-page-arrow {
	// 	display: none !important;
	// }
	#top-bar {
		height: auto;
		.col_half {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}
	.top-links,
	.top-links > ul > li {
		float: none !important;
	}
	.top-links {
		& > ul {
			text-align: center;
			& > li {
				display: inline-block;
				margin-right: -4px;
			}
		}
		ul {
			div {
				&.top-link-section {
					left: auto;
					right: 0;
					padding: 20px 15px;
				}
			}
		}
	}

	.top-links ul ul,
	.top-links ul div.top-link-section {
		text-align: left;
	}

	#top-social,
	.slider-element:not(.swiper_wrapper) .slider-caption,
	.flex-caption,
	.slider-caption p,
	.slider-caption .button,
	.slider-arrow-top-sm,
	.slider-arrow-bottom-sm {
		display: none !important;
	}

	.slider-caption {
		h2 {
			margin-bottom: 0 !important;
		}
	}

	.flex-control-nav {
		li {
			opacity: 1 !important;
		}
	}

	.fslider {
		&:not(.flex-thumb-grid) {
			.flex-control-nav {
				&.flex-control-thumbs {
					height: 60px;
				}
			}
		}
	}

	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li,
	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li img {
		display: block;
		width: 80px !important;
		height: 60px !important;
	}

	.postcontent,
	.postcontent.bothsidebar,
	.sidebar,
	.col_full,
	.col_half,
	.col_one_third,
	.col_two_third,
	.col_three_fourth,
	.col_one_fourth,
	.col_one_fifth,
	.col_two_fifth,
	.col_three_fifth,
	.col_four_fifth,
	.col_one_sixth,
	.col_five_sixth {
		width: 100%;
		margin-right: 0;
		float: none;
		margin-bottom: 50px !important;
	}

	.col_last { float: none; }

	.sidebar {
		&.col_last {
			margin-bottom: 0 !important;
		}
	}

	form {
		.col_full,
		.col_half,
		.col_one_third,
		.col_two_third,
		.col_three_fourth,
		.col_one_fourth,
		.col_one_fifth,
		.col_two_fifth,
		.col_three_fifth,
		.col_four_fifth,
		.col_one_sixth,
		.col_five_sixth {
			margin-bottom: 25px !important;
		}
	}

	#page-title {
		padding: 20px 0 25px;
		text-align: center;
		h1 {
			line-height: 1.5;
			font-size: 24px;
		}
		.breadcrumb {
			position: relative !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			margin: 20px 0 0 !important;
			justify-content: center;
		}
	}

	#page-title #portfolio-navigation,
	.portfolio-ajax-title #portfolio-navigation {
		position: relative;
		top: 0;
		left: 0;
		margin: 15px auto 0;
	}

	.portfolio-ajax-title {
		text-align: center;
	}

	/* Portfolio - Filter
	-----------------------------------------------------------------*/

	.portfolio-filter {
		border: none;
		border-radius: 0;
		margin: 0 0 20px 0 !important;
		li {
			width: 33.33%;
			margin-bottom: 20px;
			a {
				text-align: center;
				border-left: none;
				padding: 12px 18px !important;
				border-radius: 2px !important;
				&:hover {
					color: var(--theme-color);
				}
			}
			&.activeFilter {
				a {
					color: #FFF;
					margin: 0;
				}
			}
		}
	}

	.portfolio-shuffle {
		display: none;
	}

	/* Blog
	-----------------------------------------------------------------*/

	.small-thumbs .entry-image,
	 .ievent .entry-image {
		float: none !important;
		width: 100% !important;
		margin: 0 0 20px !important;
	}

	.entry-title h2,
	.post-grid .entry-title h2 {
		font-size: 18px !important;
		font-weight: 600;
	}

	.entry-meta {
		li {
			font-size: 11px;
			margin: 0 10px 10px 0;
		}
	}

	#posts {
		.entry-meta {
			li {
				i {
					display: none;
				}
			}
		}
	}

	#posts.post-grid .entry-meta li i,
	#posts .entry-meta li:last-child i {
		display: inline-block;
	}

	.post-grid {
		margin-right: 0 !important;
	}
	.post-grid .entry,
	.post-masonry-full.grid-3 .entry {
		float: none !important;
		width: 100% !important;
		margin-right: 0 !important;
		border-right: 0 !important;
	}

	.post-timeline {
		.entry {
			&.entry-date-section {
				margin-left: 0;
			}
		}
	}

	.timeline-border,
	.post-timeline .entry-timeline {
		display: none !important;
	}

	.postcontent {
		.post-timeline {
			margin: 0;
			padding-left: 0;
		}
	}

	.post-masonry-full {
		.entry {
			padding: 40px !important;
		}
	}

	.product-1 {
		.product {
			width: 100%;
		}
		.product-image {
			float: none !important;
			width: 100% !important;
			height: auto !important;
			margin: 0 0 20px !important;
		}
		.product-title {
			h3 {
				font-size: 19px;
			}
		}
		p {
			display: none;
		}
	}

	.pricing-box {
		&.best-price {
			margin-top: 0;
		}
		&.pricing-extended {
			.pricing-features {
				li {
					width: auto;
					float: none;
				}
			}
		}
	}

	.pricing {
		&.pricing-5 {
			.pricing-box {
				float: none;
				width: 100%;
			}
		}
	}

	table.cart {
		.quantity {
			display: block;
			.qty,
			.plus,
			.minus {
				float: none;
				width: 100%;
			}
			.qty {
					border: 0;
					border-top: 1px solid #DDD;
					border-bottom: 1px solid #DDD;
			}
		}
	}

	.button {
		&.button-desc {
			padding: 20px 17px;
			font-size: 15px;
			max-width: 100%;
			span { font-size: 11px; }
		}
	}

	.process-steps {
		li {
			float: none;
			width: auto !important;
			margin-top: 20px;
			&:first-child {
				margin-top: 0;
			}
			&:before,
			&:after {
				display: none;
			}
		}
	}
	h5 {
		margin: 10px 0 0 0;
	}

	.tabs-responsive .tab-container { padding: 0 !important; }

	.tabs-responsive .tab-container:not(.accordion-border) { border: 0; }

	.testimonials-grid {
		li {
			float: none;
			width: auto !important;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.team {
		&.team-list {
			.team-image {
				float: none;
				width: 100%;
				margin-right: 0;
			}
			.team-title {
				margin-top: 15px;
			}
		}
	}

	.title-block {
		padding: 2px 0 3px 15px;
		border-left-width: 5px;
		h1 {
			& + span {
				font-size: 18px;
			}
		}
		h2 {
			& + span {
				font-size: 17px;
			}
		}
		h3 {
			& + span {
				font-size: 15px;
			}
		}
		h4 {
			& + span {
				font-size: 13px;
			}
		}
	}

	.title-block-right {
		padding: 2px 15px 3px 0;
		border-right-width: 5px;
	}

	.heading-block {
		h1 {
			font-size: 30px;
			& + span {
				font-size: 20px;
			}
		}
		h2 {
			font-size: 26px;
			& + span {
				font-size: 18px;
			}
		}
		h3 {
			font-size: 22px;
		}
		h4 {
			font-size: 19px;
		}
		& ~ p {
			&:not(.lead) {
				font-size: 15px;
			}
		}
	}

	.heading-block {
		h3 + span,
		h4 + span {
			font-size: 17px;
		}
	}

	.emphasis-title {
		h1,
		h2 {
			font-size: 44px ;
		}
	}

	.wedding-head {
		font-size: 48px;
		.first-name,
		.last-name,
		.and {
			display: block;
			margin: 0 0 15px;
			text-align: center;
		}
		.first-name span,
		.last-name span {
			font-size: 36px;
		}
		.and {
			font-size: 120px;
		}
	}

	.news-carousel .overlay {
		display: none;
	}

	.landing-video {
		position: relative;
		top: 14px;
		width: 76%;
		height: auto;
		margin: 19px auto 0;
	}

	/* Cookie Notifications
	-----------------------------------------------------------------*/

	#cookie-notification {
		.container { padding-right: 15px; }

		.cookie-noti-btn {
			position: relative;
			left: 0;
			display: block;
			margin-top: 20px;
		}
	}

	/* Copyrights
	-----------------------------------------------------------------*/

	#copyrights {
		.col_half {
			text-align: center;
			&:last-child {
				// display: none;
			}
		}
		.footer-logo {
			margin-left: auto;
			margin-right: auto;
		}
	}

}

@media (min-width: 576px) and (max-width: 767.98px) {

	#header.full-header .container,
	.container-fullwidth { width: 540px !important; }

	.container.vertical-middle { width: 100% !important; }

	.full-screen:not(.force-full-screen) .container.vertical-middle { width: 540px !important; }

	#top-search form input { padding-right: 60px; }

	.swiper_wrapper:not(.force-full-screen),
	.swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide { height: 300px !important; }

	.swiper_wrapper .slider-caption h2 { font-size: 2.25rem; }

	.swiper_wrapper .slider-caption p { font-size: 1.25rem; }

	#header.transparent-header.floating-header .container {
		width: 540px;
		padding: 0 15px;
	}

	/* Portfolio - Items
	-----------------------------------------------------------------*/

	.portfolio:not(.portfolio-1) { margin: 0 -6px -6px 0; }

	.portfolio:not(.portfolio-1) .portfolio-item { padding: 0 6px 6px 0; }

	/* Portfolio - Items - 5 & 6 Columns
	-----------------------------------------------------------------*/

	.portfolio:not(.portfolio-1) .portfolio-item { width: 50%; }

	/* Portfolio - Items - Descriptions
	-----------------------------------------------------------------*/

	.portfolio:not(.portfolio-1) .portfolio-desc { padding: 15px 5px 10px; }

	.portfolio:not(.portfolio-1) .portfolio-desc h3 { font-size: 16px; }

	.portfolio:not(.portfolio-1) .portfolio-desc span { font-size: 14px; }

	.portfolio-notitle:not(.portfolio-1) .portfolio-desc {
		height: 78px;
		padding: 15px 5px;
		bottom: -79px;
	}

	.portfolio-full.portfolio-notitle:not(.portfolio-1) .portfolio-desc,
	.portfolio-nomargin.portfolio-notitle:not(.portfolio-1) .portfolio-desc {
		bottom: -78px;
		border-bottom: 0;
	}

	/* Portfolio - Items - Overlay
	-----------------------------------------------------------------*/

	.portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a.left-icon,
	.portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a.right-icon,
	.portfolio-notitle .iportfolio:hover .portfolio-overlay a.left-icon,
	.portfolio-notitle .iportfolio:hover .portfolio-overlay a.right-icon { margin-top: -55px !important; }

	.portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a { margin: 20px 0 0 !important; }

	.portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a.right-icon {
		margin-left: 5px !important;
		margin-right: 0 !important;
	}

	.portfolio.portfolio-nomargin,
	.portfolio.portfolio-full { margin: 0 !important; }

	.post-grid:not(.post-masonry) .entry-image iframe { height: 246px; }

	.shop:not(.product-1) { margin: 0 -12px -12px 0; }

	.shop:not(.product-1) .product {
		padding: 0 12px 12px 0;
		width: 50%;
	}

	.shop:not(.product-1) .product-title h3 { font-size: 17px; }

	.clients-grid:not(.grid-3):not(.grid-2) li,
	.clients-grid.grid-4 li,
	.clients-grid.grid-6 li { width: 33.33%; }

	.button.button-full { font-size: 18px; }

	.button.button-full i {
		top: 2px !important;
		margin-left: 3px !important;
	}

	.error404,
	.error404-wrap .error404 { font-size: 160px; }

}

@media (max-width: 575.98px) {

	.container,
	#header.full-header .container,
	.container-fullwidth {
		width: 100% !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.menu{
		.btn-group .btn {
			font-size: 14px;
			padding: 7px;
		}
	}

	.menu-item{
		.price {
			font-size: 16px;
		}
	}

	#primary-menu-trigger { left: 20px; }

	#primary-menu-trigger { left: 20px; }

	#top-cart { right: 40px; }

	#top-search a { right: 40px; }

	#top-search form input { padding-right: 55px; }

	#side-panel-trigger { right: 68px; }

	#top-account, #top-account-inactive { right: 68px; }

	#top-account a.btn {
		padding-left: 8px;
		padding-right: 8px;
	}

	#top-account a.btn i:last-child { display: none; }


	.swiper_wrapper:not(.force-full-screen),
	.swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
		height: 180px !important;
	}

	.swiper_wrapper {
		.slider-caption {
			h2 {
				font-size: 1.7rem;
			}
			p { font-size: 1rem; }
		}
	}

	#header {
		&.transparent-header {
			&.floating-header {
				.container {
					width: 310px;
					padding: 0 15px;
				}
			}
		}
	}

	.portfolio-filter li {
		width: 50%;
	}
	.portfolio:not(.portfolio-1) {
		margin: 0 ;
		.portfolio-item {
			width: 100%;
			padding: 0;
		}
	}

	.post-grid:not(.post-masonry) .entry-image iframe { height: 157px; }

	.mpost .entry-image {
		float: none;
		margin: 0 0 20px 0;
	}

	.mpost {
		.entry-image,
		.entry-image a,
		.entry-image img,
		.entry-image i {
			width: 100%;
			height: auto;
		}

		.entry-image i {
			height: 150px;
			line-height: 150px;
		}
	}

	.shop:not(.product-1) {
		margin: 0;
		.product {
			margin: 0 0 20px 0;
			padding: 0;
			width: 100%;
		}
		.product-title h3 { font-size: 19px; }
	}

	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs {
		height: 45px;
		li,
		li img {
			display: block;
			width: 60px !important;
			height: 45px !important;
		}
	}

	.si-share {
		span {
			display: none;
		}
	}

	.parallax .entry-overlay-meta,
	.parallax .entry-overlay {
		left: 50% !important;
		right: 0 !important;
		margin: 0 0 0 -140px !important;
		width: 280px !important;
	}

	.parallax {
		.entry-overlay {
			width: 288px !important;
		}
		.countdown-section {
			width: 64px;
			height: 64px;
			margin: 0 8px 0 0;
			font-size: 11px;
			padding: 0;
			padding-top: 12px;
		}
		.countdown-amount {
			font-size: 20px;
		}
	}

	.countdown-large {
		.countdown-section {
			display: block;
			width: auto;
			margin: 30px 0 0;
			border: 0;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.testi-image,
	.testi-image a,
	.testi-image img,
	.testi-image i {
		width: 36px;
		height: 36px;
	}

	.testi-image {
		i {
			line-height: 36px;
			font-size: 20px;
		}
	}

	.clients-grid:not(.grid-3):not(.grid-2) li,
	.clients-grid.grid-4 li,
	.clients-grid.grid-6 li {
		width: 50%;
	}

	.button {
		&.button-full {
			font-size: 16px;
			i {
				top: 2px !important;
				margin-left: 3px !important;
			}
		}
	}

	.emphasis-title h1,
		.emphasis-title h2 {
		font-size: 40px !important;
	}

	.error404,
	.error404-wrap .error404 {
		font-size: 120px;
	}

	.landing-form-overlay {
		margin-left: -155px;
	}

	.button {
		&.button-desc {
			padding: 20px 17px;
			font-size: 15px;
			max-width: 100%;
			span { font-size: 11px; }
		}
	}

}

/* ----------------------------------------------------------------
	Retina CSS
-----------------------------------------------------------------*/


@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

	#logo a.standard-logo,
	#header.split-menu #logo a.standard-logo { display: none; }

	#logo a.retina-logo { display: flex; }

	#header.split-menu #logo a.retina-logo { display: inline-block; }

	.owl-carousel.owl-loading,
	.preloader,
	.preloader2,
	.form-process {
		background-image: url('../images/preloader@2x.gif');
		background-size: 24px 24px;
	}

	.dark .owl-carousel.owl-loading,
	.dark .preloader,
	.dark .preloader2,
	.dark .form-process { background-image: url('../images/preloader-dark@2x.gif'); }

	.widget_recent_comments li {
		background-image: url("../images/icons/widget-comment@2x.png");
		background-size: 15px 26px;
	}

	.widget_nav_menu li a,
	.widget_links li a,
	.widget_meta li a,
	.widget_archive li a,
	.widget_recent_entries li a,
	.widget_categories li a,
	.widget_pages li a,
	.widget_rss li a,
	.widget_recent_comments li a {
		background-image: url("../images/icons/widget-link@2x.png");
		background-size: 4px 20px;
	}

	.dark .widget_recent_comments li { background-image: url("../images/icons/widget-comment-dark@2x.png"); }

	.dark .widget_nav_menu li a,
	.dark .widget_links li a,
	.dark .widget_meta li a,
	.dark .widget_archive li a,
	.dark .widget_recent_entries li a,
	.dark .widget_categories li a,
	.dark .widget_pages li a,
	.dark .widget_rss li a,
	.dark .widget_recent_comments li a { background-image: url("../images/icons/widget-link-dark@2x.png"); }

}

@media
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991.98px),
only screen and (   min--moz-device-pixel-ratio: 2) and (max-width: 991.98px),
only screen and (     -o-min-device-pixel-ratio: 2/1) and (max-width: 991.98px),
only screen and (        min-device-pixel-ratio: 2) and (max-width: 991.98px),
only screen and (                min-resolution: 192dpi) and (max-width: 991.98px),
only screen and (                min-resolution: 2dppx) and (max-width: 991.98px) {
	#logo a.standard-logo { display: none; }
	#logo a.retina-logo { display: flex; justify-content: center; height: 100%; align-items: center; }
}

@media (max-width: 375px) {
	.menu {
		.btn-group .btn {
			font-size: 12px;
		}
	}
	.menu-item {
		img {
			min-width: 100px;
		}
	}
}

@media (max-width: 534px) {
	
	.banner-caption h2 {
		font-size: 28px;
	}

	.sub-banner-caption h2 {
		margin-bottom: 5px;
	}
}

@media (max-width: 478px) {

	.banner-caption p {
		font-size: 14px !important;
	}

	#logo img {
		height: 55px;
	}
}

@media (max-width: 375px) {

	#logo img {
		height: 55px;
	}
}

@media screen and (max-width: 1199px) {
  
  .table-custom-responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .table-custom-responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  .table-custom-responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  .table-custom-responsive td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .table-custom-responsive td:last-child {
    border-bottom: 0;
  }
}