/*-----------------------------------------------------------------------------------

	pagetitle.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


#page-title {
	position: relative;
	padding: var(--page-title-padding) 0;
	background-color: var(--page-title-bg);
	border-bottom: 1px solid #EEE;

	.container {
		position: relative;
	}

	h1 {
		padding: 0;
		margin: 0;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 1px;
		color: #333;
		font-size: var(--page-title-size);
		text-transform: uppercase;
	}

	span {
		display: block;
		margin-top: 10px;
		font-weight: 300;
		color: #777;
		font-size: var(--page-title-subtitle-size);
	}
}

.breadcrumb {
	// position: absolute !important;
	width: auto !important;
	// top: 50% !important;
	// left: auto !important;
	// right: 15px !important;
	// margin: -10px 0 0 0 !important;
	background-color: transparent !important;
	padding: 0 !important;
	font-size: 12px;
}

body.stretched .container-fullwidth .breadcrumb { right: 60px !important; }

#header.transparent-header + #page-title.page-title-parallax .breadcrumb { margin-top: 35px !important; }

.breadcrumb {
	a {
		color: #555;

		&:hover {
			color: var(--theme-color);
		}
	}

	i {
		width: auto !important;
	}
}


/* Page Title - Dark
-----------------------------------------------------------------*/

#page-title {
	&.page-title-dark {
		background-color: #333;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.15) !important;
		border-bottom: none;

		h1 {
			color: rgba(255,255,255,0.9) !important;
		}

		span {
			color: rgba(255,255,255,0.7);
		}

		.breadcrumb > .active {
			color: rgba(255,255,255,0.6);
		}

		.breadcrumb a {
			color: rgba(255,255,255,0.8);
		}

		.breadcrumb a:hover {
			color: rgba(255,255,255,0.95);
		}
	}

	/* Page Title - Right Aligned
	-----------------------------------------------------------------*/

	&.page-title-right {
		text-align: right;

		.breadcrumb {
			left: 15px !important;
			right: auto !important;
		}
	}

	/* Page Title - Center Aligned
	-----------------------------------------------------------------*/

	&.page-title-center {
		text-align: center;

		span {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
		}

		.breadcrumb {
			position: relative !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			margin: 20px 0 0 !important;
			justify-content: center;
		}
	}

	/* Page Title - Background Pattern
	-----------------------------------------------------------------*/

	&.page-title-pattern {
		background-image: url('images/pattern.png');
		background-repeat: repeat;
		background-attachment: fixed;
	}

	/* Page Title - Parallax Background
	-----------------------------------------------------------------*/

	&.page-title-parallax {
		padding: var(--page-title-parallax-padding) 0;
		text-shadow: none;
		border-bottom: none;
		background-color: transparent;
		background-image: url('images/parallax/parallax-bg.jpg');
		background-attachment: fixed;
		background-position: 50% 0;
		background-repeat: no-repeat;

		h1 {
			font-size: var(--page-title-parallax-size);
			font-weight: 600;
			letter-spacing: 2px;
		}

		span {
			font-size: var(--page-title-parallax-subtitle-size);
		}

		.breadcrumb {
			font-size: 13px;
		}
	}

	/* Page Title - Mini
	-----------------------------------------------------------------*/

	&.page-title-mini {
		padding: var(--page-title-mini-padding) 0;

		h1 {
			font-weight: 600;
			font-size: var(--page-title-mini-size);
			color: #444;
		}

		span {
			display: none;
		}
	}

	/* Page Title - No Background
	-----------------------------------------------------------------*/

	&.page-title-nobg {
		background: transparent !important;
		border-bottom: 1px solid #F5F5F5;
	}

	/* Page Title - Video
	-----------------------------------------------------------------*/

	&.page-title-video {
		background: none;
		position: relative;
		overflow: hidden;

		.container {
			z-index: 3;
		}

		.video-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			video {
				width: 100%;
			}
		}
	}
}

