/*-----------------------------------------------------------------------------------

	typography.scss

-----------------------------------------------------------------------------------*/


dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}

::selection {
	background: var(--theme-color);
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: var(--theme-color); /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: var(--theme-color); /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
	line-height: var(--line-height-base);
	color: var(--body-color);
	font-size: var(--font-size-base);
	font-family: var(--body-font);
}

a {
	text-decoration: none !important;
	color: var(--heading-color);
	transition: all .35s ease;

	&:hover {
		color: var(--theme-color);
	}

	img {
		border: none;
	}
}

img { max-width: 100%; }

iframe { border: none !important; }


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--heading-color);
	font-weight: 700;
	line-height: var(--line-height-base);
	margin: 0 0 30px 0;
	font-family: var(--heading-font);
}

h5,
h6 { margin-bottom: 20px; }

h1 { font-size: var(--font-size-h1); }

h2 { font-size: var(--font-size-h2); }

h3 { font-size: var(--font-size-h3); }

h4 { font-size: var(--font-size-h4); }

h5 { font-size: var(--font-size-h5); }

h6 { font-size: var(--font-size-h6); }

h4 { font-weight: 600; }

h5,
h6 { font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
	> span:not(.nocolor) {
		color: var(--theme-color);
	}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { font-family: var(--body-font); }

#wrapper {
	position: relative;
	float: none;
	width: 1220px;
	margin: 0 auto;
	background-color: #FFF;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.text-extra {
	font-family: var(--secondary-font);
	font-size: 36px !important;
	font-weight: 400;
	color: var(--theme-color);
}

.text-extra-alt {
	font-family: var(--secondary-font);
	font-size: 36px !important;
	font-weight: 400;
	color: white;
}

.text-primary {
	color: var(--theme-color) !important;

	&:hover, &:focus {
		color: #0c4b4b !important;
	}
}

.text-secondary {
	color: var(--secondary-color);
}

.font-32px {
	font-size: 2rem;
}