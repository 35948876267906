/*-----------------------------------------------------------------------------------

	youtubebg.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/


.mb_YTVPlayer {
	display: block;
	transform: translateZ(0);
	transform-style: preserve-3d;
	perspective: 1000;
	backface-visibility: hidden;
	transform: translate3d(0,0,0);
	animation-timing-function: linear;
	:focus {
		outline: 0;
	}
	&.fullscreen {
		display: block!important;
		position: fixed!important;
		width: 100%!important;
		height: 100%!important;
		top: 0!important;
		left: 0!important;
		margin: 0!important;
		border: none !important;
		.mbYTP_wrapper {
			opacity: 1 !important;
		}
	}
}

.mbYTP_wrapper {
	iframe {
		max-width: 4000px !important;
	}
}

.inline_YTPlayer {
	margin-bottom: 20px;
	vertical-align: top;
	position: relative;
	left: 0;
	overflow: hidden;
	border-radius: 4px;
	-moz-box-shadow: 0 0 5px rgba(0,0,0,.7);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,.7);
	box-shadow: 0 0 5px rgba(0,0,0,.7);
	background: rgba(0,0,0,.5);
	img {
		border: none!important;
		-moz-transform: none!important;
		-webkit-transform: none!important;
		-o-transform: none!important;
		transform: none!important;
		margin: 0!important;
		padding: 0!important;
	}
}

