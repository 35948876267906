/*-----------------------------------------------------------------------------------

	helpers.scss

-----------------------------------------------------------------------------------*/


.uppercase { text-transform: uppercase !important; }

.lowercase { text-transform: lowercase !important; }

.capitalize { text-transform: capitalize !important; }

.nott { text-transform: none !important; }

.tright { text-align: right !important; }

.tleft { text-align: left !important; }

.fright { float: right !important; }

.fleft { float: left !important; }

.fnone { float: none !important; }

.ohidden {
	position: relative;
	overflow: hidden !important;
}

.line,
.double-line {
	clear: both;
	position: relative;
	width: 100%;
	margin: (var(--margin-base) + 10) 0;
	border-top: 1px solid #EEE;

	&.line-sm {
		margin: var(--margin-small) 0;
	}
}

span.middot {
	display: inline-block;
	margin: 0 5px;
}

.double-line { border-top: 3px double #E5E5E5; }

.emptydiv {
	display: block !important;
	position: relative !important;
}

.allmargin { margin: var(--margin-base) !important; }

.leftmargin { margin-left: var(--margin-base) !important; }

.rightmargin { margin-right: var(--margin-base) !important; }

.topmargin { margin-top: var(--margin-base) !important; }

.bottommargin { margin-bottom: var(--margin-base) !important; }

.clear-bottommargin { margin-bottom: -(var(--margin-base)) !important; }

.allmargin-sm { margin: var(--margin-small) !important; }

.leftmargin-sm { margin-left: var(--margin-small) !important; }

.rightmargin-sm { margin-right: var(--margin-small) !important; }

.topmargin-sm { margin-top: var(--margin-small) !important; }

.bottommargin-sm { margin-bottom: var(--margin-small) !important; }

.clear-bottommargin-sm { margin-bottom: -(var(--margin-small)) !important; }

.allmargin-lg { margin: var(--margin-big) !important; }

.leftmargin-lg { margin-left: var(--margin-big) !important; }

.rightmargin-lg { margin-right: var(--margin-big) !important; }

.topmargin-lg { margin-top: var(--margin-big) !important; }

.bottommargin-lg { margin-bottom: var(--margin-big) !important; }

.clear-bottommargin-lg { margin-bottom: -(var(--margin-big)) !important; }

.nomargin {margin: 0 !important; }

.noleftmargin { margin-left: 0 !important; }

.norightmargin { margin-right: 0 !important; }

.notopmargin { margin-top: 0 !important; }

.nobottommargin { margin-bottom: 0 !important; }

.header-stick { margin-top: -var(--margin-base) !important; }

.content-wrap .header-stick { margin-top: -var(--margin-big) !important; }

.footer-stick { margin-bottom: -var(--margin-base) !important; }

.content-wrap .footer-stick { margin-bottom: -var(--margin-big) !important; }

.noborder { border: none !important; }

.noleftborder { border-left: none !important; }

.norightborder { border-right: none !important; }

.notopborder { border-top: none !important; }

.nobottomborder { border-bottom: none !important; }

.noradius { border-radius: 0 !important; }

.col-padding { padding: 60px; }

.nopadding { padding: 0 !important; }

.noleftpadding { padding-left: 0 !important; }

.norightpadding { padding-right: 0 !important; }

.notoppadding { padding-top: 0 !important; }

.nobottompadding { padding-bottom: 0 !important; }

.noabsolute { position: relative !important; }

.noshadow { box-shadow: none !important; }

.notextshadow { text-shadow: none !important; }

.hidden { display: none !important; }

.nothidden { display: block !important; }

.inline-block {
	float: none !important;
	display: inline-block !important;
}

.center { text-align: center !important; }

.divcenter {
	position: relative !important;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.bgcolor,
.bgcolor #header-wrap { background-color: var(--theme-color) !important; }

.color { color: var(--theme-color) !important; }

.border-color { border-color: var(--theme-color) !important; }

.nobg { background: none !important; }

.nobgcolor { background-color: transparent !important; }

.t300 { font-weight: 300 !important; }

.t400 { font-weight: 400 !important; }

.t500 { font-weight: 500 !important; }

.t600 { font-weight: 600 !important; }

.t700 { font-weight: 700 !important; }

.ls0 { letter-spacing: 0px !important; }

.ls1 { letter-spacing: 1px !important; }

.ls2 { letter-spacing: 2px !important; }

.ls3 { letter-spacing: 3px !important; }

.ls4 { letter-spacing: 4px !important; }

.ls5 { letter-spacing: 5px !important; }

.noheight { height: 0 !important; }

.nolineheight { line-height: 0 !important; }

.font-body { font-family: 'Lato', sans-serif; }

.font-primary { font-family: 'Raleway', sans-serif; }

.font-secondary { font-family: 'Crete Round', serif; }

.bgicon {
	display: block;
	position: absolute;
	bottom: -60px;
	right: -50px;
	font-size: 210px;
	color: rgba(0,0,0,0.1);
}

/* MOVING BG -  TESTIMONIALS */
.bganimate {
  -webkit-animation:BgAnimated 30s infinite linear;
	 -moz-animation:BgAnimated 30s infinite linear;
	  -ms-animation:BgAnimated 30s infinite linear;
	   -o-animation:BgAnimated 30s infinite linear;
		  animation:BgAnimated 30s infinite linear;
}

@-webkit-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
}

@-moz-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
}

@-ms-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
}

@-o-keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
}

@keyframes BgAnimated {
	from  {background-position:0 0;}
	to    {background-position:0 400px;}
}


.input-block-level {
	display: block;
	width: 100% !important;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.vertical-middle {
	height: auto !important;
	left: 0;
	max-width: none !important;
	z-index: 2;

	+ .video-wrap { z-index: 1 !important; }
}

.magnific-max-width .mfp-content { max-width: 800px; }

.rounded-20px {
	border-radius: 20px;
}

.rounded-top-20px {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.rounded-bottom-20px {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.h-192px {
	height: 192px;
}

.excerpt-1 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.excerpt-2 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
