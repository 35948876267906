/*-----------------------------------------------------------------------------------

	variables.scss

-----------------------------------------------------------------------------------*/


:root {
	


/* ----------------------------------------------------------------
	Base Colors
-----------------------------------------------------------------*/


--theme-color:                   #1AA04F;
--secondary-color:               #551B1E;
--dark-color:					 #151515;
--body-color:                    #333333;
--heading-color:                 #1AA04F;


/* ----------------------------------------------------------------
	Line Height Variables
-----------------------------------------------------------------*/

--line-height-base:              1.5;
--line-height-content:           1.8;



/* ----------------------------------------------------------------
	Margins
-----------------------------------------------------------------*/

--margin-base:                   50px;
--margin-small:                  30px;
--margin-big:                    80px;



/* ----------------------------------------------------------------
	Font Families
-----------------------------------------------------------------*/

--body-font:                     "Source Sans Pro", sans-serif;
--heading-font:                  "Source Sans Pro", sans-serif;
--secondary-font:                "Source Sans Pro", sans-serif;



/* ----------------------------------------------------------------
	Font Sizes
-----------------------------------------------------------------*/

--font-size-base:                17px;
--font-size-h1:                  36px;
--font-size-h2:                  42px;
--font-size-h3:                  22px;
--font-size-h4:                  20px;
--font-size-h5:                  var(font-size-base);
--font-size-h6:                  14px;


/* ----------------------------------------------------------------
	Layouts
-----------------------------------------------------------------*/


--postcontent:                   74%;
--postcontent-margin:            4%;

--sidebar:                       100% - calc(var(--postcontent) + var(--postcontent-margin));
--bothsidebar:                   100% - calc( ( var(--sidebar) * 2 ) + ( var(--postcontent-margin) * 2 ) );


--section-padding:				60px;
--section-margin:				60px;
--section-bg:					#F9F9F9;


/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

--topbar-height:                 45px;
--topbar-line-height:            calc(var(--topbar-height) - 1px);


/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

--header-height:                 150px;
--header-height-logo:			 110px;

// Sticky Header
--sticky-header-height:          150px;
--sticky-header-height-logo:     110px;


// Floating Header Offset
--header-floating-offset:        60px;


// Side Header
--side-header-width:             260px;
--side-header-bg:                #FFF;


/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/

--primary-menu-padding:				calc(( var(--header-height) - 22px ) / 2);
--sticky-menu-padding:				calc(( var(--sticky-header-height) - 22px ) / 2);

--primary-menu-font:             	var(--heading-font);
--primary-menu-font-weight:      	600;
--primary-menu-font-size:        	13px;
--primary-menu-font-tt:          	uppercase;
--primary-menu-font-color:       	#fff;
--primary-menu-font-spacing:     	0;


// Primary Menu Sub Menu
--primary-menu-submenu-width:        220px;
--primary-menu-submenu-font:         var(body-font);
--primary-menu-submenu-font-size:    12px;
--primary-menu-submenu-font-weight:  600;


// Primary Mega menu
--mega-menu-title:                   var(--heading-font);



/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


--page-title-padding:					50px;
--page-title-parallax-padding:			100px;
--page-title-mini-padding:				20px;

--page-title-bg:						#F5F5F5;
--page-title-bg-dark:					#333;

--page-title-size:						28px;
--page-title-subtitle-size:				18px;

--page-title-parallax-size:				40px;
--page-title-parallax-subtitle-size:	22px;

--page-title-mini-size:					18px;


/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/


//Columns Margin
--portfolio-2-margin:            20px;
--portfolio-3-margin:            15px;
--portfolio-4-margin:            12px;
--portfolio-5-margin:            10px;
--portfolio-6-margin:            6px;


//Columns Margin for Sidebar
--portfolio-2-margin-sb:         20px;
--portfolio-3-margin-sb:         15px;
--portfolio-4-margin-sb:         12px;
--portfolio-5-margin-sb:         0;


//Columns Margin for Both Sidebar
--portfolio-2-margin-bs:         20px;
--portfolio-3-margin-bs:         11px;
--portfolio-4-margin-bs:         0;


/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/

//Columns Margin
--shop-3-margin:            30px;
--shop-4-margin:            20px;


//Columns Margin for Sidebar
--shop-2-margin-sb:         40px;
--shop-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
--shop-2-margin-bs:         40px;


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

//Columns Margin
--blog-2-margin:            40px;
--blog-3-margin:            30px;
--blog-4-margin:            28px;


//Columns Margin for Sidebar
--blog-2-margin-sb:         40px;
--blog-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
--blog-2-margin-bs:         40px;

}