/*-----------------------------------------------------------------------------------

	news.scss

-----------------------------------------------------------------------------------*/

.news-post {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 35% auto;
      grid-template-columns: 35% auto;
  width: 100%;
  padding: 1.5em;
  border-radius: 10px;
  margin-bottom: 1.5em;
  background-color: white;
}

.news-post-img {
  height: 100%;
  min-height: 230px;
  border-radius: 5px;
  background-position: center center;
  background-size: cover;
}

.news-post-img img {
  height: 100%;
  padding: 0 30px 0 0;
  margin: 0;
}

.news-post-info {
  position: relative;
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  height: 100%;
}

.news-post-info h3 {
  font-size: 24px;
}

.news-post-info h3 a {
  color: #444444;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-post-info h3 a:hover {
  text-decoration: none;
  color: var(--theme-color);
}

.news-post-info-excerpt {
  font-size: 0.8em !important;
  margin-top: 0.5em;
  margin-bottom: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
}

.news-post-info-meta {
  font-size: 0.75em !important;
  padding-top: 5px;
  margin-bottom: 0px;
}

.news-post-info-meta a {
  color: #EA891B;
  text-decoration: underline;
}

.news-post-info-meta a:hover {
  color: #0e1317;
  text-decoration: none;
}

.article-content {
  position: relative;
}

.article-title {
  text-transform: initial;
  text-align: left;
}

.article-opt {
  margin-bottom: 1.5em;
}

.article-opt a {
  color: #444;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 1rem;
}

.article-opt a span {
  color: var(--dark-color);
  margin-right: 20px;
}

.article-opt a:hover {
  color: var(--theme-color) !important;
  text-decoration: none;
}

.article-opt a:hover > span {
  color: var(--theme-color) !important;
}

.article-meta p {
	font-size: .85em;
}

.article-meta-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #E1E1E1;
  margin-top: 1em;
  margin-bottom: 2em;
}

.article-meta-share .article-meta-author {
  color: var(--theme-color);
}

.article-meta-share .article-meta p {
  margin: 0 !important;
}

.article-meta-share .article-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.article-meta-share .article-share-text {
  margin-right: 15px;
  font-size: .85em;
}

.article-meta-share .jssocials-shares {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.article-meta-share .jssocials-share {
  margin-right: 5px;
}

.article-meta-share .jssocials-share:last-child {
  margin-right: 0;
}

.article-meta-share .jssocials-share-link {
  border-radius: 50%;
  display: block;
  text-align: center;
}

.article-widget {
  padding-right: 50px;
  margin-bottom: 2em;
}

.article-widget-title {
    margin-bottom: 0;
}

.article-widget-badge {
  margin-top: 0.7em;
}

.article-widget-badge span {
  background: var(--theme-color);
  border-radius: 4px !important;
  font-weight: normal !important;
  font-size: 0.6em !important;
  padding: 0.5em 0.65em !important;
  margin: 0 2px 4px 0;
}

.article-widget-search {
  margin-top: 0.7em;
}

.article-widget-news {
  padding: 1em 0;
}

.article-widget-news .news-date {
  font-size: 0.65em !important;
  line-height: 1.2;
  margin-bottom: 0 !important;
}

.article-widget-news .news-title {
  font-size: 0.85em !important;
  line-height: 1.5;
  margin: 0 !important;
  margin-top: 5px !important;
}

.article-widget-news .news-title a {
  color: #444;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.article-widget-news .news-title a:hover {
  color: var(--theme-color);
}

.searchbar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.searchbar .form-search {
	width: 100%;
	padding: 0 3.5rem 0 1.5rem;
	font-size: 1rem;
	margin-right: 0 !important;
}

.searchbar .form-submit-search {
	width: 3.5rem;
	height: 2.8rem;
	margin-left: -3.5rem;
	background: none;
	border: none;
	outline: none;
	z-index: 50;
}

.searchbar .form-submit-search [class^="icon-"] {
	font-weight: bold;
	font-size: 20px;
	color: var(--theme-color);
}

.searchbar .form-submit-search:hover {
	cursor: pointer;
}

.search {
  padding-bottom: 2em;
}

.search form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.search .form-control {
  height: calc(2.65rem + 10px);
}

.search .search-icon {
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 40px;
  padding: 10px 1.2rem;
  color: #AFAFAF;
}

.search input[type="text"] {
  font-size: 1em;
  border-color: #afafaf !important;
  border-radius: 5px !important;
  padding-left: 15px;
  position: relative;
  z-index: 1;
  color: #0e1317;
  margin-right: 15px;
  background: white !important;
}

.search input[type="text"]:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-color: var(--theme-color) !important;
}