/* Clouds */
.cloud-wrap {
	bottom: 0;
	left: 0;
	padding-top: 80px;
	position: absolute;
	right: 0;
	top: 0;
	overflow: hidden;
	opacity: .09; /* Set Cloud Opacity */
}

/* KEYFRAMES */
@-webkit-keyframes animateCloud {
	0% { margin-left: -1000px; }
	100% { margin-left: 100%; }
}

@-moz-keyframes animateCloud {
	0% { margin-left: -1000px; }
	100% { margin-left: 100%; }
}

@keyframes animateCloud {
	0% { margin-left: -1000px; }
	100% { margin-left: 100%; }
}

/* ANIMATIONS */
.c1 {
	-webkit-animation: animateCloud 35s linear infinite;
	-moz-animation: animateCloud 35s linear infinite;
	animation: animateCloud 35s linear infinite;
	-webkit-transform: scale(0.65);
	-moz-transform: scale(0.65);
	transform: scale(0.65);
}

.c2 {
	-webkit-animation: animateCloud 20s linear infinite;
	-moz-animation: animateCloud 20s linear infinite;
	animation: animateCloud 20s linear infinite;
	-webkit-transform: scale(0.3);
	-moz-transform: scale(0.3);
	transform: scale(0.3);
}

.c3 {
	-webkit-animation: animateCloud 30s linear infinite;
	-moz-animation: animateCloud 30s linear infinite;
	animation: animateCloud 30s linear infinite;
	-webkit-transform: scale(0.5);
	-moz-transform: scale(0.5);
	transform: scale(0.5);
}

.c4 {
	-webkit-animation: animateCloud 18s linear infinite;
	-moz-animation: animateCloud 18s linear infinite;
	animation: animateCloud 18s linear infinite;
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	transform: scale(0.4);
}

.c5 {
	-webkit-animation: animateCloud 25s linear infinite;
	-moz-animation: animateCloud 25s linear infinite;
	animation: animateCloud 25s linear infinite;
	-webkit-transform: scale(0.55);
	-moz-transform: scale(0.55);
	transform: scale(0.55);
}

.cloud {
	background: #618faf;
	background: -moz-linear-gradient(top,  #FFF 5%, #F1F1F1 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(5%,#FFF), color-stop(100%,#F1F1F1));
	background: -webkit-linear-gradient(top,  #FFF 5%,#F1F1F1 100%);
	background: -o-linear-gradient(top,  #FFF 5%,#F1F1F1 100%);
	background: -ms-linear-gradient(top,  #FFF 5%,#F1F1F1 100%);
	background: linear-gradient(top,  #FFF 5%,#F1F1F1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#F1F1F1',GradientType=0 );
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	-webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
	height: 120px;
	position: relative;
	width: 350px;
}

.cloud::after, .cloud::before {
	background: #618faf;
	content: '';
	position: absolute;
	z-indeX: -1;
}

.cloud::after {
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
	height: 100px;
	left: 50px;
	top: -50px;
	width: 100px;
}

.cloud::before {
	-webkit-border-radius: 200px;
	-moz-border-radius: 200px;
	border-radius: 200px;
	width: 180px;
	height: 180px;
	right: 50px;
	top: -90px;
}