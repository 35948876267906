/*-----------------------------------------------------------------------------------

	content.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/

html {
    scroll-behavior: smooth;
}


#content {
	position: relative;
	// overflow: hidden;
	background-color: #FFF;

	p {
		line-height: var(--line-height-content);
	}

	.content-wrap {
		position: relative;
		padding: 140px 0;
	}

	.container {
		position: relative;
	}
}

.grid-container {
	position: relative;
	overflow: hidden;
}

body:not(.device-touch) .grid-container {
	-webkit-transition: height .4s linear;
	-o-transition: height .4s linear;
	transition: height .4s linear;
}

.overlay {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    background: linear-gradient(#0B4C26 0%, #9CBCA5 100%);
    opacity: 1;
    bottom: 0;
}

.overlay-2 {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    background: linear-gradient(white 0%, #641616 100%);
    opacity: 1;
    bottom: 0;
}

.overlay-dark {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: rgba(8, 75, 39, 0.8);
    opacity: 1;
    bottom: 0;
}

#section-cta {
	background: url(../images/banner/cta-bg.jpg) fixed 50% 50% no-repeat;
    background-size: cover;
    position: relative;
}

#section-menu {
	&:after{
        position: absolute;
        content:"";
        left: 0px;
        top: 0px;
        background: url("../images/bg/food.png") right bottom;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        z-index: 1;
    }
}

.btn-custom {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: #fff;
}

@media (max-width: 991px) {
    #section-intro {
        .mt-n5 {
            margin-top: 0 !important;
        }
    }
}

.btn-main {
  background: var(--theme-color);
  color: white;
  border-color: var(--theme-color);
 
  &:hover {
    border-color: var(--dark-color);
    background: var(--dark-color);
    color: white;
  }
}

#addToCartModal1, #addToCartModal2 {
    .modal-header .close {
        padding: 1rem;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        background: white;
        opacity: 1;
    }
}

#top-account .top-account-content a {
    &:hover {
        color: var(--theme-color) !important;
    }
}

.bg-primary{
  background: var(--theme-color) !important;
}

.bg-secondary {
    background: #f8f6f3 !important;
}

.font-extra{
	font-family: var(--secondary-font);
}


.font-md{
	font-size:36px;
  line-height: 46px;
}


.font-lg{
	font-size: 60px;
  line-height: 70px;
}

.top-search-form {
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    position: absolute;
    width: 100% !important;
    height: 100%;
    padding: 0 20px;
    margin: 0;
    top: 0;
    left: 0;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
}

.top-search-form input {
    box-shadow: none !important;
    border-radius: 0;
    border: 0;
    outline: 0 !important;
    font-size: 1rem;
    padding: 10px 80px 10px 15px;
    height: 100%;
    background-color: white !important;
    color: #333;
    font-weight: 700;
    margin-top: 0 !important;
}

.top-search-open .top-search-form {
    opacity: 1;
    z-index: 10;
    pointer-events: auto;
}

.shape-divider-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    overflow: hidden;
    line-height: 0;
    z-index: 2;
}

.shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
}

.shape-divider-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
    transform: rotate(180deg) translateY(100%);
}

.shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
}

.overlay-trigger-icon.size-lg {
    width: 64px;
    height: 64px;
    line-height: 64px;
    margin: 0 0.5rem;
    font-size: 1.5rem;
}

.overlay-trigger-icon {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 0.25rem;
    font-size: 1.125rem;
    text-align: center;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
    transition: all .3s ease;
}

.bg-overlay {
    z-index: 5;
}

.bg-overlay, .bg-overlay-bg, .bg-overlay-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.bg-overlay-content {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: 20px;
}

.search-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #e3eaef;
    margin-bottom: 20px
}

.book-card {
	position: relative;
	will-change: transform;
	transition: box-shadow .3s ease;
	box-shadow: 0 10px 40px transparent;
}

.book-card.hover-in {
	transition: -webkit-transform .2s ease-out;
	transition: transform .2s ease-out;
	transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.book-card.hover-out {
	transition: -webkit-transform .2s ease-in;
	transition: transform .2s ease-in;
	transition: transform .2s ease-in, -webkit-transform .2s ease-in;
}

.book-wrap { -moz-transform-style: flat !important; }

#canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.ellipsis {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: var(--theme-color);
    display: -moz-box;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
}