/*-----------------------------------------------------------------------------------

	Shortcodes: carousel.scss

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/

.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in { z-index: 0; }

.owl-carousel .owl-animated-out { z-index: 1; }

.owl-carousel .fadeOut  {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes fadeOut {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.owl-height {
	-webkit-transition:height 500ms ease-in-out;
	-o-transition:height 500ms ease-in-out;
	transition:height 500ms ease-in-out
}

.owl-carousel {
	display:none;
	-webkit-tap-highlight-color:transparent;
	position:relative;
	z-index:1;
	width: 100%;
}

.owl-carousel .owl-stage {
	position:relative;
	touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content:".";
	display:block;
	clear:both;
	visibility:hidden;
	line-height:0;
	height:0
}

.owl-carousel .owl-stage-outer {
	position:relative;
	overflow:hidden;
	transform: translate3d(0,0,0);
}

.owl-carousel.owl-loaded { display:block }

.owl-carousel.owl-loading {
	display:block;
	min-height: 100px;
	background: url('../images/preloader.gif') no-repeat center center
}

.owl-carousel .owl-refresh .owl-item { display:none }

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	display:block;
	width:100%;
	transform-style:preserve-3d;
}

#slider .owl-carousel .owl-item img { transform-style: preserve-3d; }

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled { display: none; }

.owl-nav .owl-prev,
.owl-nav .owl-next,
.owl-dot,
.owl-dots button {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded { display: block; }

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden { opacity: 0; }

.mega-menu-content .owl-carousel.owl-hidden { opacity: 1; }

.owl-carousel.owl-refresh .owl-item { display: none; }

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl { direction: rtl; }

.owl-carousel.owl-rtl .owl-item { float: right; }

.no-js .owl-carousel { display: block; }

.owl-carousel .owl-item .owl-lazy {
	opacity:0;
	-webkit-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease
}

.owl-carousel .owl-item img { transform-style:preserve-3d }

.owl-carousel .owl-video-wrapper {
	position:relative;
	height:100%;
	background:#111
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 64px;
	width: 64px;
	left: 50%;
	top: 50%;
	margin-left: -32px;
	margin-top: -32px;
	background: url("images/icons/play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition:scale(1.3,1.3);
	-o-transition:scale(1.3,1.3);
	transition:scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn { display:none }

.owl-carousel .owl-video-tn {
	opacity:0;
	height:100%;
	background-position:center center;
	background-repeat:no-repeat;
	-webkit-background-size:contain;
	-moz-background-size:contain;
	-o-background-size:contain;
	background-size:contain;
	-webkit-transition:opacity 400ms ease;
	-o-transition:opacity 400ms ease;
	transition:opacity 400ms ease
}

.owl-carousel .owl-video-frame {
	position:relative;
	z-index:1;
	height: 100%;
	width: 100%;
}


/* Owl Carousel - Controls
-----------------------------------------------------------------*/

.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	line-height: 1;
}

/* Owl Carousel - Controls - Arrows
-----------------------------------------------------------------*/

.owl-carousel .owl-nav [class*=owl-] {
	position: absolute;
	top: 50%;
	margin-top: -18px;
	left: -36px;
	zoom: 1;
	width: 36px;
	height: 36px;
	line-height: 32px;
	border: 1px solid rgba(0,0,0,0.2);
	color: #666;
	background-color: #FFF;
	font-size: 18px;
	border-radius: 50%;
	opacity: 0;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.owl-carousel.with-carousel-dots .owl-nav [class*=owl-] { margin-top: -38px; }

.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
	margin-top: -30px;
	left: 0 !important;
	height: 60px;
	line-height: 60px;
	border: none;
	color: #EEE;
	background-color: rgba(0,0,0,0.4);
	font-size: 28px;
	border-radius: 0 3px 3px 0;
}

.owl-carousel-full .with-carousel-dots .owl-nav [class*=owl-] { margin-top: -50px; }

.owl-carousel  .owl-nav .owl-next {
	left: auto;
	right: -36px;
}

.slider-element .owl-nav .owl-next,
.owl-carousel-full  .owl-nav .owl-next {
	left: auto !important;
	right: 0 !important;
	border-radius: 3px 0 0 3px;
}

.owl-carousel:hover .owl-nav [class*=owl-] {
	opacity: 1;
	left: -18px;
}

.owl-carousel:hover .owl-nav .owl-next {
	left: auto;
	right: -18px;
}

.owl-carousel .owl-nav [class*=owl-]:hover {
	background-color: var(--theme-color) !important;
	color: #FFF !important;
	text-decoration: none;
}

.owl-carousel .owl-nav .disabled { display: none !important; }


/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/

.owl-carousel {
	.owl-dots {
		.owl-dot {
			display: inline-block;
			zoom: 1;
			margin-top: 30px;
			*display: inline;
			span {
				display: block;
				width: 8px;
				height: 8px;
				margin: 0 4px;
				opacity: 0.5;
				border-radius: 50%;
				background-color: var(--theme-color);
				-webkit-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
			}
			&.active,
			&:hover {
				span { opacity: 1; }
			}
		}
	}
}

